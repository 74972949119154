var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-tabs',{staticClass:"settings",attrs:{"default-active-key":"1"}},[_c('a-tab-pane',{key:"1",attrs:{"tab":"基础设置"}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',{attrs:{"form":_vm.form}},[_c('h3',[_vm._v("邮费设置")]),_c('a-divider'),_c('a-form-item',{attrs:{"label":"启用邮费","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"help":"未启用时，订单付款金额小于 100 将加收 10 元邮费"}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'shipping.is_open',
              {
                initialValue: false,
                valuePropName: 'checked'
              }
            ]),expression:"[\n              'shipping.is_open',\n              {\n                initialValue: false,\n                valuePropName: 'checked'\n              }\n            ]"}]})],1),_c('a-form-item',{attrs:{"label":"邮费","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['shipping.amount']),expression:"['shipping.amount']"}],attrs:{"precision":0}})],1),_c('a-form-item',{attrs:{"label":"包邮金额","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['shipping.threshold']),expression:"['shipping.threshold']"}],attrs:{"precision":0}})],1),_c('h3',[_vm._v("退款设置")]),_c('a-divider'),_c('a-form-item',{attrs:{"label":"显示退款按钮","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'refund.is_open',
              {
                initialValue: false,
                valuePropName: 'checked'
              }
            ]),expression:"[\n              'refund.is_open',\n              {\n                initialValue: false,\n                valuePropName: 'checked'\n              }\n            ]"}]})],1),_c('h3',[_vm._v("收货地址设置")]),_c('a-divider'),_c('a-form-item',{attrs:{"label":"显示修改收货地址按钮","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'address.edit_addr',
              {
                initialValue: false,
                valuePropName: 'checked'
              }
            ]),expression:"[\n              'address.edit_addr',\n              {\n                initialValue: false,\n                valuePropName: 'checked'\n              }\n            ]"}]})],1)],1),_c('a-divider'),_c('div',{staticClass:"actions"},[_c('a-button',{staticClass:"submit",attrs:{"type":"primary"},on:{"click":_vm.onSubmit}},[_vm._v(" 完成 ")])],1)],1)],1),_c('a-tab-pane',{key:"2",attrs:{"tab":"延迟下单名单"}},[_c('v-delay-list')],1),_c('a-tab-pane',{key:"3",attrs:{"tab":"禁止下单名单"}},[_c('v-blocked-list')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }