



























































































import Vue from "vue";
import { mapState } from "vuex";
import { dispatch, Settings } from "@/store";

export default Vue.extend({
  data() {
    return {
      loading: true,
      form: this.$form.createForm(this),
      labelCol: { span: 11 },
      wrapperCol: { span: 13 }
    };
  },
  computed: {
    ...mapState(["settings"])
  },
  methods: {
    onSubmit() {
      if (this.loading) {
        return;
      }

      this.form.validateFieldsAndScroll(
        { scroll: { offsetTop: 200 } },
        (errors, values) => {
          if (!errors) {
            const settings: Settings = {
              ...values
            };
            this.loading = true;
            dispatch
              .settingsPost(settings)
              .then(() => {
                this.$message.success("商城设置已修改");
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          }
        }
      );
    }
  },
  mounted() {
    dispatch.settingsGet().then(() => {
      this.form.setFieldsValue({
        ...this.settings
      });
      this.loading = false;
    });
  }
});
